import View from './view.js'
import React from 'react'
import {
  useDataChange,
  useDataValue,
  useDataOriginalValue,
} from 'Simple/Data.js'
import { storage } from 'Data/helpers.js'
import { STORAGE_KEY_IMAGE_CAPTURE } from 'Data/constants.js'

let ReviewLogic = props => {
  let originalValue = useDataOriginalValue({
    viewPath: props.viewPath,
    context: 'imageCapture',
  })
  let value = useDataValue({
    viewPath: props.viewPath,
    context: 'imageCaptureSelected',
    path: 'answers',
  })
  let changeQuestions = useDataChange({
    viewPath: props.viewPath,
    context: 'imageCapture',
  })
  let changeSelected = useDataChange({
    viewPath: props.viewPath,
    context: 'imageCaptureSelected',
  })

  let id = useDataValue({
    context: 'flow_shortcuts',
    viewPath: props.viewPath,
    path: 'id',
  })

  let images = Object.entries(value)
    .map(item => item[1] && !!item[1]?.image && item[1])
    .filter(Boolean)

  let handleReset = () => {
    storage.removeItem(`${STORAGE_KEY_IMAGE_CAPTURE}_${id}`)
    changeQuestions(originalValue)
    changeSelected(next => {
      next.current_question_id = 0
      next.answers = {}
    })
  }

  return <View {...props} images={images} handleReset={handleReset} />
}

export default ReviewLogic
