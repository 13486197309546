import { capitalize } from 'Data/format.js'
export function populateTemplate(template, values) {
  if (!template) return template

  let re = /\{\{(.+?)\}\}/gim
  let output = template

  let t = [...template.matchAll(re)]
  t.forEach(item => {
    output = output.replaceAll(item[0], capitalize(values[item[1]]))
  })

  return output
}
