import { useDataChange } from 'Simple/Data'
import { normalizePath, useSetFlowTo } from 'Simple/Flow'

export function useOnClick(props) {
  let change = useDataChange({
    context: 'imageCaptureQuestion',
    viewPath: props.viewPath,
  })
  let setFlowTo = useSetFlowTo(props.viewPath)

  return function onClick() {
    change(next => {
      next.answer = null
    })

    setFlowTo(normalizePath(props.viewPath, '../../Upload'))
  }
}
