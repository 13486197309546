// @view
import React from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { getStorageDevice, setStorageDevice } from './helpers.js'
import { useEffect } from 'react'
import View from './view.js'

export default function Content(props) {
  let devices = useDataValue({
    context: 'videoCapture',
    path: 'devices',
    viewPath: props.viewPath,
  })

  let selectedDevice = useDataValue({
    context: 'videoCapture',
    path: 'selectedDevice',
    viewPath: props.viewPath,
  })

  let changeSelectedDevice = useDataChange({
    context: 'videoCapture',
    path: 'selectedDevice',
    viewPath: props.viewPath,
  })

  let changeIsVideoInitialized = useDataChange({
    context: 'videoCapture',
    path: 'isVideoInitialized',
    viewPath: props.viewPath,
  })

  function onChange(id) {
    changeIsVideoInitialized(false)
    props.handleClear()
    setStorageDevice(id)
    changeSelectedDevice(id)
  }

  useEffect(() => {
    if (!devices?.length) {
      changeSelectedDevice(undefined)
      return
    }

    if (
      selectedDevice &&
      devices.some(item => item.deviceId === selectedDevice)
    ) {
      return
    }

    let device = getStorageDevice()
    if (!device || !devices.some(item => item.deviceId === device)) {
      device = devices[0].deviceId
    }

    onChange(device)
  }, [devices, selectedDevice]) // eslint-disable-line

  return devices?.length > 1 ? (
    <View
      onChange={e => onChange(e.target.value)}
      value={selectedDevice || ''}
      disabled={props.preview}
      options={devices?.map(device => ({
        value: device.deviceId,
        id: device.deviceId,
        text: device.label,
      }))}
      style={{
        padding: '4px',
        marginTop: '0px',
        marginBottom: '8px',
        borderRadius: '8px',
        color: '#606060',
        outline: 'none',
        fontSize: '11px',
        alignSelf: 'center',
      }}
    />
  ) : null
}
