import { useDataChange } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let questionChange = useDataChange({
    viewPath: props.viewPath,
    context: 'question',
    path: 'answer',
  })

  return async function onChange(value) {
    if (!value.locationId) return

    questionChange(value)
  }
}
