import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let question_id = useDataValue({
    viewPath: props.viewPath,
    context: 'imageCaptureQuestion',
    path: 'id',
  })

  let questions = useDataValue({
    viewPath: props.viewPath,
    context: 'imageCapture',
    path: 'questions',
  })

  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'imageCaptureSelected',
  })

  return function onClick() {
    change(next => {
      next.answers[question_id] = question_id
      let next_question_index =
        questions.findIndex(item => item.id === question_id) + 1
      if (next_question_index < questions.length) {
        next.current_question_id = questions[next_question_index].id
      }
    })
  }
}

export function label(question, questions, prompt) {
  return `Photo ${
    questions
      .filter(item => item.type === 'instructions')
      .findIndex(item => item.id === question.id) + 1
  } - ${prompt}`
}
