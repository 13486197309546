import View from './view.js'
import React from 'react'

export default function Logic(props) {
  return (
    <View
      {...props}
      data-view-path={props.parentViewPath}
      isSelected={
        props.isSelected ||
        (props.id &&
          props.id === props.selected &&
          props.selectedLocationVisitType &&
          props.selectedLocationVisitType === props.locationVisitType)
      }
      onClick={() => {
        if (typeof props.onClick === 'function') {
          props.onClick(props.id, props.locationVisitType)
        }
      }}
    />
  )
}
