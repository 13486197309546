import { useDataChange, useDataValue } from 'Simple/Data'
import { useEffect, useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let theme = useDataValue({
    viewPath: props.viewPath,
    context: 'design_tokens_theme',
  })

  let themeChange = useDataChange({
    viewPath: props.viewPath,
    context: 'design_tokens_theme',
  })

  useEffect(() => {
    if (!data) return
    if (data?.settings?.theme !== theme) {
      themeChange(data?.settings?.theme)
    }
  }, [data]) //eslint-disable-line

  return useMemo(() => {
    if (!data) return data

    return data
  }, [data])
}
