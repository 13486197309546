import { useDataChange, useDataValue } from 'Simple/Data.js'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'

export function useOnClick(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  let value = useDataValue({
    viewPath: props.viewPath,
    context: 'answer',
  })
  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'current_question_id',
  })

  return function onClick() {
    change(value.id)
    setFlowTo(normalizePath(props.viewPath, '../../Question'))
  }
}
