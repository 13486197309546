import { useMemo } from 'react'
import parseISO from 'date-fns/parseISO'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let answer = useDataValue({
    context: 'question',
    viewPath: props.viewPath,
    path: 'answer',
  })

  return useMemo(() => {
    if (!data) return data

    return {
      ...data,
      ...answer,
      month:
        !(answer?.month instanceof Date) && answer?.month
          ? parseISO(answer.month)
          : new Date(),
    }
  }, [data]) //eslint-disable-line
}
