// this is an autogenerated file from App/Widget/Content/Questions/save-progress-mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Widget_Content_Questions(
  $person: lead_save_progress_person
  $responsible: lead_save_progress_responsible
  $insurance: MedicalPlan
  $appointment: jsonb
  $location: jsonb
  $images: jsonb
  $answers: jsonb
) {
  leads_save_progress(
    person: $person
    responsible: $responsible
    insurance: $insurance
    appointment: $appointment
    location: $location
    images: $images
    answers: $answers
  ) {
    ok
  }
}

`