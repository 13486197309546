import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let question_option_text = useDataValue({
    context: 'question_option',
    path: 'text',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'question',
    path: 'answer',
    viewPath: props.viewPath,
  })

  return function onClick(value) {
    change({
      value,
      text: question_option_text,
      displayValues: ['text'],
    })
  }
}

export function populateTemplate(template, values) {
  if (!template) return template

  let re = /\{\{(.+?)\}\}/gim
  let output = template

  let t = [...template.matchAll(re)]
  t.forEach(item => {
    output = output.replaceAll(item[0], values[item[1]])
  })

  return output
}
