import MeasureWrapper from 'react-measure'
import View from './view.js'
import { forwardRef } from 'react'

let Measure = forwardRef(
  ({ children, onResize, viewPath, innerRef, ...props }, ref) => {
    let wrapperRef = innerRef || ref

    return (
      <MeasureWrapper ref={wrapperRef} bounds onResize={onResize}>
        {({ measureRef }) => (
          <View measureRef={measureRef} viewPath={viewPath} {...props}>
            {typeof children === 'function' ? children() : children}
          </View>
        )}
      </MeasureWrapper>
    )
  }
)

export default Measure
