import { useDataValue } from 'Simple/Data'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let questions = useDataValue({
    context: 'widget',
    path: 'questions',
    viewPath: props.viewPath,
  })

  return useMemo(() => {
    let imageCaptureQuestions = questions.filter(
      question => question.id === 'imagecapture' && question?.questionData
    )?.[0]

    return {
      questions: imageCaptureQuestions?.questionData || [],
    }
  }, [questions])
}
