let DEFAULT_MEDICAL_PLAN = 'PRIVATE_INSURANCE'

export function getView(
  has_booking,
  location_visit_type,
  insurance_answer,
  default_insurance
) {
  if (!has_booking) {
    return 'NoBooking'
  }

  // if there is a location visit type explicitly selected by the user, then use it
  if (location_visit_type) {
    return location_visit_type === 'virtual' ? 'Virtual' : 'InPerson'
  }

  let insurance = insurance_answer || default_insurance || DEFAULT_MEDICAL_PLAN
  // else base the decision on the default insurance settings
  return insurance.toUpperCase().includes('_VIRTUAL') ? 'Virtual' : 'InPerson'
}
