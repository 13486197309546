export function containsItemWithId(list, item) {
  return Array.isArray(list) && list.some(litem => litem.id === item.id)
}

export function not(value, valueCompare) {
  return value !== valueCompare
}

export function join(...values) {
  return values.join(' ')
}

export function joinNoSpace(...values) {
  return values.join('')
}

export function any(...values) {
  return values.find(Boolean)
}

export function fallbackIfSame(a, b, fallback) {
  return a === b ? fallback : a
}

export function listEquals(a, b) {
  if (a.length === b.length) {
    return a.every(element => {
      if (b.includes(element)) {
        return true
      }

      return false
    })
  }

  return false
}

export function equals(a, b) {
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
    return a === b

  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()

  if (a.prototype !== b.prototype) return false

  if (
    (Array.isArray(a) && !Array.isArray(b)) ||
    (Array.isArray(b) && !Array.isArray(a))
  ) {
    return false
  }

  let keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false

  return keys.every(k => equals(a[k], b[k]))
}

export function equalLength(a, b) {
  return a?.length === b?.length
}

export function includes(list, value) {
  return Array.isArray(list) && list.includes(value)
}

export function includesAll(list, values) {
  return (
    Array.isArray(list) &&
    Array.isArray(values) &&
    values.every(value => list.includes(value))
  )
}

export function truncate(str, limit = 30, ending = '...') {
  if (str.length <= limit) return str
  let lastSpace = str.slice(0, limit - ending.length + 1).lastIndexOf(' ')
  return (
    str.slice(0, lastSpace > 0 ? lastSpace : limit - ending.length) + ending
  )
}

export function and(a, b) {
  return a && b
}

export function isNotFirst(list, key) {
  return list?.findIndex(item => item === key) > 0
}
