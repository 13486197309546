// this is an autogenerated file from App/Widget/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Widget{
  widget: leads_get_configuration {
    settings
    questions
  }
}

`