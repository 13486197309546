import React, { useRef } from 'react'
import View from './view.js'

let CAPTURE_QUALITY = 1

export default function Logic({
  preview,
  onCapture = _ => null,
  onClear = () => null,
  ...props
}) {
  let ref = {
    canvasRef: useRef(),
    videoRef: useRef(),
    overlayRef: useRef(),
    landmarksRef: useRef(),
    measureWrapperRef: useRef(),
  }
  function handleCapture() {
    ref.canvasRef.current.width = ref.videoRef.current.videoWidth
    ref.canvasRef.current.height = ref.videoRef.current.videoHeight
    let context = ref.canvasRef.current.getContext('2d')
    context.drawImage(
      ref.videoRef.current,
      0,
      0,
      ref.videoRef.current.videoWidth,
      ref.videoRef.current.videoHeight
    )
    ref.canvasRef.current.toBlob(
      blob => onCapture(blob),
      'image/jpeg',
      CAPTURE_QUALITY
    )
    ref.videoRef.current.pause()
  }

  function handleClear() {
    if (ref.canvasRef.current) {
      let context = ref.canvasRef.current.getContext('2d')
      context.clearRect(
        0,
        0,
        ref.canvasRef.current.width,
        ref.canvasRef.current.height
      )
      onClear()
      ref.videoRef.current.play()
    }
  }

  return (
    <View
      {...props}
      preview={preview}
      handleClick={!preview ? handleCapture : handleClear}
      handleClear={handleClear}
      innerRef={ref}
    />
  )
}
