import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'question',
  })

  let question = useDataValue({
    viewPath: props.viewPath,
    context: 'question',
  })

  return function onClick() {
    submit({ type: question.required && !question?.canSkip ? 'next' : 'skip' })
  }
}

export function canSkip(hasAnswer, isRequired, isValid) {
  if (hasAnswer && isValid && !isRequired) return 'Next'

  return isRequired ? 'Next' : 'Skip'
}
