export function populateTemplate(template, values) {
  if (!template) return ''

  let re = /\{\{(.+?)\}\}/gim
  let output = template

  let t = [...template.matchAll(re)]
  t.forEach(item => {
    output = output.replaceAll(item[0], values[item[1]])
  })

  return output
}
