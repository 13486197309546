import { useDataFormat, useDataValue } from 'Simple/Data'
import { useMemo } from 'react'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let currentQuestionId = useDataValue({
    context: 'selected',
    path: 'current_question_id',
    viewPath: props.viewPath,
  })

  let answer = useDataFormat({
    context: 'selected',
    path: 'answers',
    format: value => value[currentQuestionId],
    viewPath: props.viewPath,
  })

  let question = useDataFormat({
    context: 'widget',
    path: 'questions',
    format: value =>
      currentQuestionId
        ? value.find(l => l.id === currentQuestionId)
        : value[0],
    viewPath: props.viewPath,
  })

  return useMemo(
    () => ({
      ...question,
      answer,
    }),
    [currentQuestionId] // eslint-disable-line react-hooks/exhaustive-deps
    // ignore question, answer
  )
}
