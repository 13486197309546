import { EMPTY_PLACEHOLDER } from 'Data/constants.js'
import { listEquals } from 'Data/aggregate.js'

function keyValues(obj, separator = ', ') {
  // if no displayValues defined give back the values joined
  let keys = obj.displayValues || Object.keys(obj)
  return keys.map(key => obj[key]).join(separator)
}

export function questionsAndAnswers(
  flowQuestions,
  questions,
  answers,
  current_question_id
) {
  let answered = flowQuestions
    .map(item => {
      let questionDetails = questions?.find(question => question.id === item)

      if (answers?.[item] && !questionDetails.isInformationOnly) {
        // if answer is an object we use the displayValues to know what keys to pick for display
        let answerValue =
          typeof answers?.[item] === 'object'
            ? keyValues(answers?.[item])
            : answers?.[item]

        return {
          ...questionDetails,
          answerValue,
        }
      }
      return false
    })
    .filter(Boolean)

  let required = questions
    .map(item => {
      return item.required && flowQuestions.includes(item.id) ? item.id : false
    })
    .filter(Boolean)

  let done = answered.map(item => item.id)

  if (listEquals(required, done)) {
    return answered
  }

  return [
    ...answered,
    flowQuestions?.length !== Object.keys(answers).length && {
      ...questions?.find(
        item =>
          item.id ===
          flowQuestions.filter(
            question => !Object.keys(answers).includes(question)
          )[0]
      ),
      answerValue: EMPTY_PLACEHOLDER,
    },
  ].filter(Boolean)
}
