import { fromPromise } from 'wonka'

async function getMock(query) {
  let viewPath = viewPathFromGraphql(query.definitions[0].name.value)
  try {
    return await fetch(`src${viewPath}/data-mock.json`).then(r => r.json())
  } catch (error) {
    if (error instanceof SyntaxError) {
      console.error({
        type: 'Api/MockClient/missing-data-mock.json',
        solution: `Make src${viewPath}/data-mock.json with the shape of the data.graphql query or subscription`,
        error,
        query,
        viewPath,
      })
    }

    return { data: undefined }
  }
}

export default function createMockClient(client) {
  if (process.env.REACT_APP_ENV === 'development') {
    if (sessionStorage.getItem('ApiMockClient')) {
      // TODO: pass through to client
      return {
        ...client,
        executeQuery: ({ query }) => fromPromise(getMock(query)),
        executeSubscription: ({ query }) => fromPromise(getMock(query)),
      }
    }
  }

  return client
}

function viewPathFromGraphql(viewPath) {
  return viewPath.replace(/^app_/, '').replace(/_/g, '/')
}
