// This is an Object.entries() polyfill to support old browsers
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/entries#Polyfill
if (!Object.entries) {
  Object.entries = function (obj) {
    let ownProps = Object.keys(obj),
      i = ownProps.length,
      resArray = new Array(i) // preallocate the Array
    while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]]
    return resArray
  }
}

// Object.fromEntries() polyfill
// https://vanillajstoolkit.com/polyfills/objectfromentries/
if (!Object.fromEntries) {
  Object.fromEntries = function (entries) {
    if (!entries || !entries[Symbol.iterator]) {
      throw new Error(
        'Object.fromEntries() requires a single iterable argument'
      )
    }
    let obj = {}
    for (let [key, value] of entries) {
      obj[key] = value
    }
    return obj
  }
}
