import React, { useState, useEffect } from 'react'
import { useCardRatio } from './useCardRatio'
import * as faceApi from 'face-api.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import View from './view.js'

let FACE_API_OPTIONS = new faceApi.TinyFaceDetectorOptions({
  inputSize: 512,
  scoreThreshold: 0.5,
})

function calculateVideoDimensions(video) {
  if (video) {
    let videoRatio = video.videoWidth / video.videoHeight
    let width = video.offsetWidth
    let height = video.offsetHeight
    let elementRatio = width / height
    if (elementRatio > videoRatio) {
      width = height * videoRatio
    } else {
      height = width / videoRatio
    }
    return {
      width,
      height,
    }
  }
  return null
}

function isDisplaySizeValid(size) {
  if (size) {
    return size.width !== 0 && size.height !== 0
  }
  return false
}

export default function Content(props) {
  let { videoRef, canvasRef, overlayRef, landmarksRef, measureWrapperRef } =
    props.innerRef
  let [aspectRatio, calculateRatio] = useCardRatio(2)
  let [isPositioned, setIsPositioned] = useState(false)
  let [isSmiling, setIsSmiling] = useState(false)

  let mediaStream = useDataValue({
    context: 'mediaStream',
    viewPath: props.viewPath,
  })

  let container = useDataValue({
    context: 'videoCapture',
    path: 'container',
    viewPath: props.viewPath,
  })

  let isVideoInitialized = useDataValue({
    context: 'videoCapture',
    path: 'isVideoInitialized',
    viewPath: props.viewPath,
  })

  let changeIsVideoInitialized = useDataChange({
    context: 'videoCapture',
    path: 'isVideoInitialized',
    viewPath: props.viewPath,
  })

  let changeContainer = useDataChange({
    context: 'videoCapture',
    path: 'container',
    viewPath: props.viewPath,
  })

  let buttonText = !props.preview ? 'Take a picture' : 'Take another picture'

  function instructionsText(showOverlay = true) {
    if (!showOverlay) return ''

    if (!isPositioned) {
      return 'For optimal results, make sure you position yourself inside the overlay boundaries'
    } else if (!isSmiling) {
      return 'Show your smile before taking a picture'
    } else {
      return "Perfect, you're all set to take a photo"
    }
  }

  let currentInstructions = !props.preview
    ? instructionsText(props.showOverlay)
    : 'Preview of your photo'

  function onResize(contentRect) {
    changeContainer({
      width: contentRect.bounds.width,
      height: Math.round(contentRect.bounds.width / aspectRatio) ?? 0,
    })
  }

  function adjustMeasure() {
    try {
      measureWrapperRef?.current?.measure()
    } catch (_) {
      console.warn(`Couldn't run measure`)
    }
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth)
  }

  function onCanPlay() {
    // need to manually trigger measure as the permission dialog if they
    // haven't let the camera have access before, doesn't re-fire the resizeobserver
    adjustMeasure()
    changeIsVideoInitialized(true)
    if (!props.preview) videoRef.current.play()
  }

  function isFaceCentered(landmarks) {
    let DIMENSIONS = {
      LEFT:
        videoRef?.current?.offsetWidth / 2 -
        overlayRef?.current?.offsetWidth / 2,
      TOP: overlayRef?.current?.offsetTop,
      RIGHT:
        videoRef?.current?.offsetWidth / 2 +
        overlayRef?.current?.offsetWidth / 2,
      BOTTOM:
        overlayRef?.current?.offsetTop + overlayRef?.current?.offsetHeight,
    }

    let BUFFER_SPACE =
      DIMENSIONS.RIGHT <= DIMENSIONS.BOTTOM
        ? (DIMENSIONS.BOTTOM / DIMENSIONS.RIGHT) * 0.5
        : (DIMENSIONS.RIGHT / DIMENSIONS.BOTTOM) * 0.2

    return landmarks.every(landmark => {
      let sortedX = [...landmark].sort((a, b) => a._x - b._x)
      let sortedY = [...landmark].sort((a, b) => a._y - b._y)
      return (
        sortedX[0]._x - BUFFER_SPACE > DIMENSIONS.LEFT &&
        sortedX[sortedX.length - 1]._x + BUFFER_SPACE < DIMENSIONS.RIGHT &&
        sortedY[0]._y - BUFFER_SPACE > DIMENSIONS.TOP &&
        sortedY[sortedY.length - 1]._y + BUFFER_SPACE < DIMENSIONS.BOTTOM
      )
    })
  }

  async function onPlay() {
    if (
      !isVideoInitialized ||
      !videoRef.current ||
      videoRef.current.hidden ||
      videoRef.current.paused ||
      videoRef.current.ended ||
      props.preview ||
      !faceApi.nets.tinyFaceDetector.params ||
      !props.showOverlay
    ) {
      return
    }

    let result = await faceApi
      .detectSingleFace(videoRef.current, FACE_API_OPTIONS)
      .withFaceLandmarks()
      .withFaceExpressions()

    let displaySize = calculateVideoDimensions(videoRef.current)
    let isDisplayValid = isDisplaySizeValid(displaySize)

    if (result && isDisplayValid) {
      faceApi.matchDimensions(landmarksRef.current, displaySize)
      let resizedDetections = faceApi.resizeResults(result, displaySize)
      let { landmarks, expressions } = resizedDetections
      let leftEye = landmarks.getLeftEye()
      let rightEye = landmarks.getRightEye()
      let mouth = landmarks.getMouth()
      let expressionList = Object.keys(expressions)
      let currentExpression = expressionList.reduce((a, b) =>
        expressions[a] > expressions[b] ? a : b
      )

      setIsPositioned(isFaceCentered([mouth, leftEye, rightEye]))
      setIsSmiling(currentExpression === 'happy')

      landmarksRef.current.innerHTML = faceApi.createCanvasFromMedia(
        videoRef.current
      )

      landmarksRef &&
        landmarksRef.current &&
        landmarksRef.current
          .getContext('2d')
          .clearRect(0, 0, displaySize.width, displaySize.height)
      landmarksRef &&
        landmarksRef.current &&
        faceApi.draw.drawDetections(landmarksRef.current, resizedDetections)
      landmarksRef &&
        landmarksRef.current &&
        faceApi.draw.drawFaceLandmarks(landmarksRef.current, resizedDetections)
      landmarksRef &&
        landmarksRef.current &&
        faceApi.draw.drawFaceExpressions(
          landmarksRef.current,
          resizedDetections
        )
    } else {
      setIsPositioned(false)
      setIsSmiling(false)
    }
    setTimeout(async () => await onPlay(), 200)
  }

  useEffect(() => {
    if (mediaStream && videoRef.current) {
      videoRef.current.srcObject = mediaStream
    }
  }, [mediaStream, videoRef])

  useEffect(() => {
    return () => {
      mediaStream?.getTracks().forEach(track => track.stop())
    }
  }, [])

  return (
    <View
      {...props}
      {...props.theme}
      buttonText={buttonText}
      isVideoInitialized={isVideoInitialized}
      currentInstructions={currentInstructions}
      containerHeight={container.height}
      containerWidth={container.width}
      videoRef={videoRef}
      isPlaced={isSmiling && isPositioned}
      videoHidden={!isVideoInitialized || props.preview}
      overlayRef={overlayRef}
      landmarksRef={landmarksRef}
      canvasRef={canvasRef}
      measureWrapperRef={measureWrapperRef}
      onCanPlay={onCanPlay}
      onPlay={onPlay}
      onResize={onResize}
    />
  )
}
