import { useMemo } from 'react'
import { useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let answer = useDataValue({
    context: 'question',
    viewPath: props.viewPath,
    path: 'answer',
  })

  return useMemo(() => {
    if (!data) return data
    return { ...data, ...answer }
  }, [data]) //eslint-disable-line
}
