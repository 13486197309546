import load from 'load-script2'

let queue = {}

export default function loadScript(url) {
  if (!queue[url]) {
    queue[url] = load(url)
  }
  return queue[url]
}
