import format from 'date-fns/format'
import parse from 'date-fns/parse'
import isValidDate from 'date-fns/isValid'
import parseISO from 'date-fns/parseISO'
import { conformToMask } from 'text-mask-core'

let CORE_FORMAT = 'HH:mm'
let APP_FORMAT = 'h:mm a'

export function formatDate(rvalue, formatIn, formatOut) {
  let value =
    formatIn === 'iso' ? parseISO(rvalue) : parse(rvalue, formatIn, new Date())
  return isValidDate(value) ? format(value, formatOut) : rvalue
}

export function fromCore(time) {
  return format(parse(time, CORE_FORMAT, new Date()), APP_FORMAT)
}

export function toCore(time) {
  format(parse(time, APP_FORMAT, new Date()), CORE_FORMAT)
}

export function timeISOIn(value) {
  return formatDate(value, 'iso', 'h:mm a')
}

export function getIsoTimeStringDisplay(value, military = false) {
  if (!value.includes('T')) return value

  let suffix = ''
  let rTime = value.split('T')[1].split('Z')[0]
  let [hours, mins] = rTime.split(':')

  if (!military) {
    suffix = 'am'
    if (hours > 12) {
      hours = hours - 12
      suffix = 'pm'
    }
  }
  return `${hours}:${mins}${suffix}`
}

export function getIsoDateStringDisplay(value, format = 'MM/dd/yyyy') {
  if (!value.includes('T')) return value

  let rDate = value.split('T')[0]
  let [yyyy, mm, dd] = rDate.split('-')

  return format.replace('yyyy', yyyy).replace('MM', mm).replace('dd', dd)
}

export function getTimeDisplay(value) {
  return value.includes('Z') ? format(parseISO(value), 'h:mm aaa') : value
}

let PHONE_NUMBER_US_MASK = [
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]
let DEFAULT_PHONE_CODE = '+1'
export function phoneNumberUS(rvalue) {
  if (!rvalue || !rvalue.startsWith(DEFAULT_PHONE_CODE)) return rvalue

  return conformToMask(
    rvalue.replace(DEFAULT_PHONE_CODE, ''),
    PHONE_NUMBER_US_MASK
  ).conformedValue
}

export function phone(value) {
  let result = (value || '').replace(/[^0-9+]/g, '')
  if (!result || result.startsWith('+1')) {
    return result
  } else if (result.startsWith('1') && result.length > 10) {
    return `+${result}`
  } else return `+1${result}`
}

export function booleanToYesNo(value) {
  return value ? 'Yes' : 'No'
}

export function isNotEmptyList(list) {
  return Array.isArray(list) && list.length > 0
}

export function listLength(list) {
  return Array.isArray(list) ? list.length : 0
}

export function isEmpty(value) {
  if (!value) return true
  if (typeof value === 'object' && isNotEmptyList(Object.keys(value)))
    return false

  if (isNotEmptyList(value) || value.length > 0) return false

  return false
}

export function addPrefixIfMissing(number) {
  let result = (number || '').replace(/[^0-9+]/g, '')
  if (!result || result.startsWith('+1')) {
    return result
  } else if (result.startsWith('1') && result.length > 10) {
    return `+${result}`
  } else return `+1${result}`
}

export function isPhoneNumber(rvalue) {
  if (typeof rvalue !== 'string') return false

  let value = addPrefixIfMissing(rvalue.replace(/[^0-9+]/g, ''))

  return /^\+[1-9]\d{10,14}$/.test(value)
}

export function hasManyItems(list) {
  return Array.isArray(list) && list.length > 1
}

export function getOneOrManyView(list) {
  return hasManyItems(list) ? 'Many' : 'One'
}

export function not(value) {
  return !value
}

export function isEmptyList(list) {
  return Array.isArray(list) && list.length === 0
}

export function capitalize(value) {
  if (!value || typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

export function toPostgresArray(list) {
  return `{${list.map(item => JSON.stringify(item)).join(',')}}`
}

export function fromPostgresArray(input) {
  if (!input || !input.startsWith('{') || !input.endsWith('}')) {
    return null
  }
  return input
    .substring(1, input.length - 1)
    .split(',')
    .filter(Boolean)
    .map(item => JSON.parse(item))
}

export function toLowerCase(value) {
  return value.toLowerCase()
}

export function getFileExtension(file) {
  return (
    file.extension ||
    file.name?.split('.').pop() ||
    file.type.split('/').pop()
  ).toLowerCase()
}

export function textToNumber(value) {
  let res =
    typeof value === 'string'
      ? parseFloat(value.replace(/[^0-9.]/g, ''))
      : value

  return isNaN(res) ? 0 : res
}

export function hasHTML(text) {
  let urlPattern = /<[a-z][\s\S]*>/gi
  return urlPattern.test(text)
}

export function stripHtml(text) {
  if (text === null) return ''

  let parser = new DOMParser()
  let doc = parser.parseFromString(text, 'text/html')
  return doc.body.textContent || ''
}
