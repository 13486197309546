import {
  STORAGE_KEY,
  STORAGE_KEY_IMAGE_CAPTURE,
  STORAGE_KEY_AUTH,
} from 'Data/constants.js'
import { useDataValue, useDataChange } from 'Simple/Data.js'
import { storage } from 'Data/helpers.js'
import { useSetFlowTo } from 'Simple/Flow.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useMutation } from 'urql'
import mutation from './mutation.graphql.js'
import { useUppy } from 'DesignSystem/FileUploaderProvider/react.js'
import { base64ToBlob } from 'Logic/base64.js'
import { capitalize } from 'Data/format.js'

import { getData } from './helpers.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let uppy = useUppy(props)
  let images = []

  let id = useDataValue({
    context: 'flow_shortcuts',
    viewPath: props.viewPath,
    path: 'id',
  })

  let questions = useDataValue({
    context: 'widget',
    viewPath: props.viewPath,
    path: 'questions',
  })

  let change = useDataChange({
    context: 'widget',
    viewPath: props.viewPath,
  })

  let theme = useDataValue({
    context: 'design_tokens_theme',
    viewPath: props.viewPath,
  })

  return async function onSubmit({ value }) {
    // handle images if included
    if (storage.getItem(`${STORAGE_KEY_IMAGE_CAPTURE}_${id}`)) {
      // image capture answers
      let { answers } = JSON.parse(
        storage.getItem(`${STORAGE_KEY_IMAGE_CAPTURE}_${id}`)
      )

      let images = Object.keys(answers)
        .map(
          key =>
            answers[key]?.image && {
              ...answers[key],
              type: key,
            }
        )
        .filter(Boolean)

      uppy.getFiles().forEach(file => uppy.removeFile(file.id))

      uppy.addFiles(
        images.map(item => ({
          name: `${item.type}.jpg`,
          type: 'image/jpeg',
          data: base64ToBlob(item.image.split(',')[1], 'image/jpeg'),
          meta: {
            useRawName: true,
            key: item.type,
          },
          source: 'Local',
          isRemote: false,
        }))
      )

      try {
        let upload = await uppy.upload()
        if (upload.successful) {
          images = upload.successful.map(({ meta, s3Multipart, ...rest }) => ({
            meta,
            s3Multipart,
          }))
          await submitData({ value, images, questions })
        }
      } catch (error) {
        return notify(
          notifyError(
            'Something went wrong. Please, try again or contact your clinic.'
          )
        )
      }
    } else {
      await submitData({ value, images, questions })
    }
    return
  }

  async function submitData({ value, images, questions }) {
    try {
      let response = await executeMutation(
        getData({ value, images, questions })
      )

      if (response.error) {
        return notify(
          notifyError(
            'Something went wrong. Please, try again or contact your clinic.'
          )
        )
      }
    } catch (error) {
      return notify(
        notifyError(
          'Something went wrong. Please, try again or contact your clinic.'
        )
      )
    }

    // clean out images capture storage
    if (storage.getItem(`${STORAGE_KEY_IMAGE_CAPTURE}_${id}`)) {
      storage.removeItem(`${STORAGE_KEY_IMAGE_CAPTURE}_${id}`)
    }

    // claan cache
    storage.removeItem(`${STORAGE_KEY}_${id}`)

    // clean out the auth storage
    storage.removeItem(`${STORAGE_KEY_AUTH}_${id}`)

    change(next => {
      next.hasBooking = !!value.answers?.appointment
    })

    setFlowTo(`/App/Widget/Content/Questions/${capitalize(theme)}Complete`)
  }
}
