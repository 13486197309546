import { useMemo } from 'react'
import parse from 'date-fns/parse'
import format from 'date-fns/format'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  return useMemo(() => {
    if (!data) return data

    return data.reduce((accumulator, currentValue) => {
      accumulator[currentValue.date] = currentValue.slotsAvailability.map(
        slot => {
          return {
            start: format(
              parse(
                `${currentValue.date}T${slot}`,
                "yyyy-MM-dd'T'HH:mm",
                new Date()
              ),
              "yyyy-MM-dd'T'HH:mm:ss'Z'" // added because it was taking DST/TZ's into account with formatISO
            ),
          }
        }
      )
      return accumulator
    }, {})
  }, [data])
}
