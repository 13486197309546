import { useDataChange } from 'Simple/Data'
import compressImage from 'browser-image-compression'

let MAX_FILE_SIZE = 10485760
// 10485760  = 10MB (1MB is 1024x1024)

export function useOnChange(props) {
  let change = useDataChange({
    context: 'imageCaptureQuestion',
    viewPath: props.viewPath,
  })

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      let reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  return async function onChange(event) {
    let value = event.target.files[0]

    if (!value) {
      return
    }

    try {
      value = await compressImage(value, { maxSizeMB: 1 })
    } catch (error) {}

    if (value.size > MAX_FILE_SIZE) {
      console.log(`The image cannot be larger than 10MB`)
      return
    }

    let base64 = await blobToBase64(value)

    change(next => {
      next.answer = {
        image: base64,
        preview: base64,
      }
    })
  }
}
