let keys = {
  public: {
    leads_get_configuration_response: () => null,
    aws_token_vending_machine_response: () => null,
  },
  lead: {
    leads_get_configuration_response: () => null,
    appointment_widget_request_schedule_slots: () => null,
    CalendarDay: () => null,
    aws_token_vending_machine_response: () => null,
  },
}

export default keys
