/** @type {import('Simple/types.js').useDataConfiguration} */
import format from 'date-fns/format'
import { useDataValue } from 'Simple/Data.js'

let DEFAULT_MEDICAL_PLAN = 'PRIVATE_INSURANCE'

function encodeDate(year, month) {
  return format(new Date(year, month), `yyyy-MM-dd'T'HH:mm:ss.SSS'Z'`)
}

function getFirstAndLastDay(date) {
  let month = date.getMonth()
  let year = date.getFullYear()

  return {
    firstDay: encodeDate(year, month),
    lastDay: encodeDate(year, month + 1),
  }
}

export default function useDataConfiguration(props) {
  let answer = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
    path: 'answers',
  })

  let settings = useDataValue({
    context: 'widget',
    viewPath: props.viewPath,
    path: 'settings',
  })

  let selectedSlotMonth = useDataValue({
    context: 'selected_slot',
    viewPath: props.viewPath,
    path: 'month',
  })

  let settingsInsurance = useDataValue({
    viewPath: props.viewPath,
    context: 'widget',
    path: 'settings.defaultInsurance',
  })
  let locationVisitType = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
    path: 'answers.location.locationVisitType',
  })

  let { firstDay, lastDay } = getFirstAndLastDay(
    selectedSlotMonth || new Date()
  )

  let location_id =
    parseInt(answer.location?.id, 10) || settings?.location_numeric_id

  let insurance =
    answer.insurance?.value || settingsInsurance || DEFAULT_MEDICAL_PLAN

  return {
    variables: {
      from: firstDay,
      until: lastDay,
      location_id,
      // if no location question is present, then use the default settings
      medical_plan: locationVisitType
        ? `${insurance.toUpperCase().replace('_VIRTUAL', '')}${
            locationVisitType === 'virtual' ? '_VIRTUAL' : ''
          }`
        : insurance.toUpperCase(),
    },
    pause: !location_id || !insurance,
  }
}
