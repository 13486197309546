import { useDataChange, useDataValue } from 'Simple/Data'
import { useState } from 'react'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let questionChange = useDataChange({
    viewPath: props.viewPath,
    context: 'question',
    path: 'answer',
  })

  let answers = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'answers',
  })

  let [consentState, setConsentState] = useState(true)

  return function onChange(rvalue, change) {
    let patientPhone = answers['patient.phoneDetails']
    let resetDetails = {}

    let value = {
      ...rvalue,
      ...(rvalue?.responsiblePersonPhoneDetails?.usePatientPhone &&
      patientPhone?.phoneNumber
        ? {
            phoneType: patientPhone.phoneType,
            phoneNumber: patientPhone.phoneNumber,
          }
        : resetDetails),
    }

    if (!value.allowConsent) {
      change(next => {
        next.allowNotifications = value.allowConsent
      })
      setConsentState(false)
    }

    if (!consentState && value.allowNotifications) {
      change(next => {
        next.allowNotifications = true
        next.allowConsent = true
      })
      setConsentState(true)
    }

    questionChange(value)
  }
}
