import { useDataValue, useDataChange } from 'Simple/Data.js'
import * as validateFns from 'Data/validate.js'

function check(question, answer) {
  // if no specific validation defined, but it's required, if the answer is set return true
  if (!question?.validation && question?.required && answer) return true
  if (question?.canSkip) return true

  // validation value must match a function in validate.js
  let validate = validateFns?.[question?.validation]

  if (answer && question.validation && typeof validate === 'function') {
    return typeof answer === 'object'
      ? validate(answer[question.validation])
      : validate(answer)
  }

  return !!(question?.required && answer)
}

function filterFlowQuestions(questions, answers) {
  return questions
    .flatMap(item => {
      if (!Array.isArray(item?.dependsOn)) return item.id

      let depends = item.dependsOn.map(dep => {
        let validate = validateFns?.[dep.validation]
        return validate(answers[dep.questionId], dep.value) && item.id
      })

      return depends.length > 0 && depends
    })
    .filter(Boolean)
}

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let questions = useDataValue({
    context: 'imageCapture',
    path: 'questions',
    viewPath: props.viewPath,
  })

  let flowQuestionsChange = useDataChange({
    context: 'imageCaptureSelected',
    path: 'flowQuestions',
    viewPath: props.viewPath,
  })

  let answers = useDataValue({
    context: 'imageCaptureSelected',
    path: 'answers',
    viewPath: props.viewPath,
  })

  return async function onChange(value, change) {
    let question = questions.find(item => item.id === value.id)
    let answer = value.answer ?? answers[value.id]
    let isValid = check(question, answer)

    let filtered = filterFlowQuestions(questions, {
      ...answers,
      [question.id]: answer,
    })

    flowQuestionsChange(filtered)

    if (value.isValid === isValid) return

    change(next => {
      next.isValid = isValid

      if (answers[value.id] && !value.answer && value.answer !== '') {
        next.answer = answers[value.id]
      }
    })
  }
}
