import { useDataChange, useDataValue } from 'Simple/Data'
import {
  getIsoTimeStringDisplay,
  getIsoDateStringDisplay,
} from 'Data/format.js'

let DEFAULT_MEDICAL_PLAN = 'PRIVATE_INSURANCE'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let answer = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
    path: 'answers',
  })
  let locationVisitType = useDataValue({
    context: 'selected',
    viewPath: props.viewPath,
    path: 'answers.location.locationVisitType',
  })
  let questionChange = useDataChange({
    viewPath: props.viewPath,
    context: 'question',
    path: 'answer',
  })

  let settingsInsurance = useDataValue({
    viewPath: props.viewPath,
    context: 'widget',
    path: 'settings.defaultInsurance',
  })

  return async function onChange(value, change) {
    if (value?.start) {
      let { medical_plan, ...rest } = value

      let insurance =
        answer.insurance?.value || settingsInsurance || DEFAULT_MEDICAL_PLAN

      let data = {
        ...rest,
        displayDate: getIsoDateStringDisplay(value.start, 'MM/dd/yyyy'),
        selectedDay: getIsoDateStringDisplay(value.start, 'yyyy-MM-dd'),
        displayTime: getIsoTimeStringDisplay(value.start),
        selectedTime: getIsoTimeStringDisplay(value.start, true),
        displayValues: ['displayDate', 'displayTime'],
        // if no location question is present, then use the default settings
        medicalPlan: locationVisitType
          ? `${insurance.toUpperCase().replace('_VIRTUAL', '')}${
              locationVisitType === 'virtual' ? '_VIRTUAL' : ''
            }`
          : insurance.toUpperCase(),
      }

      questionChange(data)
    }
  }
}
