import { normalizePath, useSetFlowTo } from 'Simple/Flow'
import React, { useEffect } from 'react'
import * as faceApi from 'face-api.js'
import View from './view.js'

let MODEL_URL = '/models'

export default function Logic(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  async function initializeFaceApi() {
    setFlowTo(normalizePath(props.viewPath, 'Loading'))
    try {
      await Promise.all([
        faceApi.nets.tinyFaceDetector.loadFromUri(MODEL_URL),
        faceApi.nets.faceLandmark68Net.loadFromUri(MODEL_URL),
        faceApi.nets.faceRecognitionNet.loadFromUri(MODEL_URL),
        faceApi.nets.faceExpressionNet.loadFromUri(MODEL_URL),
      ])
      setFlowTo(normalizePath(props.viewPath, 'Content'))
    } catch (e) {
      setFlowTo(normalizePath(props.viewPath, 'Error'))
    }
  }

  useEffect(() => {
    initializeFaceApi()
  }, [])

  return <View {...props} faceApi={faceApi} />
}
