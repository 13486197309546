import { useDataChange } from 'Simple/Data.js'
import addMonths from 'date-fns/addMonths'
import subMonths from 'date-fns/subMonths'

export function useOnClickNextMonth(props) {
  let change = useDataChange({
    context: 'selected_slot',
    viewPath: props.viewPath,
  })

  return function onClickNextMonth() {
    change(next => {
      next.month = addMonths(next.month, 1)
    })
  }
}

export function useOnClickPrevMonth(props) {
  let change = useDataChange({
    context: 'selected_slot',
    viewPath: props.viewPath,
  })

  return function onClickPrevMonth() {
    change(next => {
      next.month = subMonths(next.month, 1)
    })
  }
}

export function useOnChange(props) {
  let change = useDataChange({
    context: 'selected_slot',
    viewPath: props.viewPath,
    path: 'start',
  })

  return async function onChange(value) {
    change(value.start)
  }
}
