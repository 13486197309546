import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'
import { storage } from 'Data/helpers.js'
import { STORAGE_KEY_IMAGE_CAPTURE } from 'Data/constants.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let questions = useDataValue({
    context: 'imageCapture',
    path: 'questions',
    viewPath: props.viewPath,
  })

  let id = useDataValue({
    context: 'flow_shortcuts',
    viewPath: props.viewPath,
    path: 'id',
  })

  let newState = useMemo(
    () => ({ ...data, flowQuestions: questions.map(item => item.id) }),
    [questions, data]
  )
  let previousState = storage.getItem(`${STORAGE_KEY_IMAGE_CAPTURE}_${id}`)
  let parsedState = useMemo(
    () => (previousState ? JSON.parse(previousState) : null),
    [previousState]
  )

  return useMemo(() => {
    if (!data) return data

    return {
      ...(parsedState ?? newState),
    }
  }, [data, parsedState, newState])
}
