import { useState, useCallback } from 'react'

export function useCardRatio(initialParams) {
  let [aspectRatio, setAspectRatio] = useState(initialParams)

  let calculateRatio = useCallback((height, width) => {
    if (height && width) {
      let isLandscape = height <= width
      let ratio = isLandscape ? width / height : height / width

      setAspectRatio(ratio)
    }
  }, [])

  return [aspectRatio, calculateRatio]
}
