import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let question_id = useDataValue({
    viewPath: props.viewPath,
    context: 'question',
    path: 'id',
  })

  let flowQuestions = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'flowQuestions',
  })

  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
  })

  return function onClick() {
    change(next => {
      let prev_question_index =
        flowQuestions.findIndex(item => item === question_id) - 1
      if (prev_question_index >= 0) {
        next.current_question_id = flowQuestions[prev_question_index]
      }
    })
  }
}
