import { useDataValue } from 'Simple/Data.js'
import { useMemo } from 'react'
import { storage } from 'Data/helpers.js'
import { STORAGE_KEY } from 'Data/constants.js'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let questions = useDataValue({
    context: 'widget',
    path: 'questions',
    viewPath: props.viewPath,
  })

  let id = useDataValue({
    context: 'flow_shortcuts',
    viewPath: props.viewPath,
    path: 'id',
  })

  let newState = useMemo(
    () => ({ ...data, flowQuestions: questions.map(item => item.id) }),
    [questions, data]
  )

  return useMemo(() => {
    if (!data) return data

    let previousState = storage.getItem(`${STORAGE_KEY}_${id}`)

    let parsedState = previousState ? JSON.parse(previousState) : null

    return {
      ...(parsedState ?? newState),
    }
  }, [data, newState, id])
}
