import { useDataChange, useDataValue } from 'Simple/Data.js'
import { normalizePath, useSetFlowTo } from 'Simple/Flow.js'
import React, { useEffect } from 'react'
import View from './view.js'

let CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: 'environment' },
}

export default function Logic(props) {
  let setFlowTo = useSetFlowTo(props.viewPath)
  let deviceId = useDataValue({
    context: 'videoCapture',
    path: 'selectedDevice',
    viewPath: props.viewPath,
  })

  let changeDevices = useDataChange({
    context: 'videoCapture',
    path: 'devices',
    viewPath: props.viewPath,
  })

  let changeMediaStream = useDataChange({
    context: 'mediaStream',
    viewPath: props.viewPath,
  })

  async function setDevices() {
    // eslint-disable-next-line compat/compat
    let devices = await navigator.mediaDevices.enumerateDevices()
    let videoInputs = devices.filter(device => device.kind === 'videoinput')
    changeDevices(videoInputs)
  }

  async function setMediaStream() {
    setFlowTo(normalizePath(props.viewPath, 'Loading'))
    try {
      // eslint-disable-next-line compat/compat
      let stream = await navigator.mediaDevices.getUserMedia({
        ...CAPTURE_OPTIONS,
        video: { deviceId },
      })

      changeMediaStream(stream)
      setDevices()
      setFlowTo(normalizePath(props.viewPath, 'Content'))
    } catch (err) {
      setFlowTo(normalizePath(props.viewPath, 'Error'))
    }
  }

  useEffect(() => {
    if (!deviceId) {
      setFlowTo(normalizePath(props.viewPath, 'No'))
    }
    setMediaStream()
  }, [deviceId]) // eslint-disable-line

  return <View {...props} />
}
