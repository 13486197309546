import { capitalize } from 'Data/format.js'

export function formatAnswer(value) {
  if (Array.isArray(value)) {
    return value.join(', ')
  }

  if (value === true) {
    return 'Yes'
  }
  if (value === false) {
    return 'No'
  }

  return value
}

export function populateTemplate(template, values) {
  if (!template) return template

  let re = /\{\{(.+?)\}\}/gim
  let output = template

  let t = [...template.matchAll(re)]
  t.forEach(item => {
    output = output.replaceAll(item[0], capitalize(values[item[1]]))
  })

  return output
}

export function hasTextTransform(value) {
  return value || 'capitalize'
}
