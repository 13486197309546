import { useDataFormat, useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataTransform} */
export default function useDataTransform(props, data) {
  let currentQuestionId = useDataValue({
    context: 'imageCaptureSelected',
    path: 'current_question_id',
    viewPath: props.viewPath,
  })

  return useDataFormat({
    context: 'imageCapture',
    path: 'questions',
    format: questions => {
      return currentQuestionId
        ? questions.find(l => l.id === currentQuestionId)
        : questions[0]
    },
    viewPath: props.viewPath,
  })
}
