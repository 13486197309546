// @view
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

let BLACK_LIST = ['treatment-flow']

function isBlacklisted(value) {
  return BLACK_LIST.some(item => value.includes(item))
}

export default function GoogleTagManager(props) {
  if (process.env.REACT_APP_ENV === 'development') {
    if (!process.env.REACT_APP_GTM_ID) {
      console.error(`You're missing process.env.REACT_APP_GTM_ID`)
    }
    if (!process.env.REACT_APP_GTM_ID_AUTH) {
      console.error(`You're missing process.env.REACT_APP_GTM_ID_AUTH`)
    }
    if (!process.env.REACT_APP_GTM_ID) {
      console.error(`You're missing process.env.REACT_APP_GTM_ID_PREVIEW`)
    }
  }

  useEffect(() => {
    // stop it re-initialising or prevent if route is in blacklist
    if (window.dataLayer || isBlacklisted(document.location.pathname)) return

    TagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
      auth: process.env.REACT_APP_GTM_ID_AUTH,
      preview: process.env.REACT_APP_GTM_ID_PREVIEW,
      dataLayer: {
        ...props?.dataLayer,
        version: `${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_ENV}`,
        env: `${process.env.REACT_APP_ENV}`,
      },
    })
  }, [props?.dataLayer])
  return null
}
