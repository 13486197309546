let WORDS = {
  1: 'one',
  2: 'two',
  3: 'three',
  4: 'four',
  5: 'five',
  6: 'six',
  7: 'seven',
  8: 'eight',
  9: 'nine',
  10: 'ten',
}

export function welcomeText(value) {
  return `Detailed instructions will be provided for each of the ${
    WORDS[value.filter(item => item.type === 'picture').length]
  } required photos.`
}
