import React, { useEffect } from 'react'
import View from '../../view.js'
import { useMutation } from 'Data/Api.js'
import { useSetFlowTo } from 'Simple/Flow.js'
import mutation from './mutation.graphql.js'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import { storage } from 'Data/helpers.js'
import { STORAGE_KEY_AUTH } from 'Data/constants.js'
import { getAccessTokenData, isTokenExpired } from 'Data/Auth.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import useRecaptcha from 'Logic/useRecaptcha.js'

export default function Logic(props) {
  let recaptcha = useRecaptcha()
  let setFlowTo = useSetFlowTo(props.viewPath)
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  let changeAuth = useDataChange({
    context: 'auth',
    viewPath: props.viewPath,
  })

  let id = useDataValue({
    context: 'flow_shortcuts',
    viewPath: props.viewPath,
    path: 'id',
  })

  useEffect(() => {
    if (!recaptcha.isError) {
      return
    }

    setFlowTo('/App/RuntimeError')
    return false
  }, [recaptcha.isError]) // eslint-disable-line

  useEffect(() => {
    async function authenticate(id) {
      if (!recaptcha.isReady) {
        return
      }
      let value = JSON.parse(storage.getItem(`${STORAGE_KEY_AUTH}_${id}`))

      let shouldRequestToken =
        !value ||
        (value &&
          isTokenExpired({
            access_token: value.access_token,
            access_token_data: value?.access_token_data,
          }))

      if (shouldRequestToken) {
        let captcha_token = await recaptcha.getToken()

        let mutationResponse = await executeMutation(
          {
            id,
            captcha_token,
          },
          {
            fetchOptions: {
              headers: {
                'x-hasura-role': 'public',
              },
            },
          }
        )
        if (
          mutationResponse.errors ||
          mutationResponse.data?.auth_leads?.status !== 'ok'
        ) {
          setFlowTo('/App/Auth/NotFound')
          return false
        }

        value = {
          access_token: mutationResponse.data.auth_leads.access_token,
          access_token_data: getAccessTokenData(
            mutationResponse.data.auth_leads.access_token
          ),
          jwt_expires_in:
            mutationResponse.data.auth_leads.access_token_expires_in,
          api_role: 'lead',
          authorization_headers: {
            'x-hasura-role': 'lead',
            Authorization: `Bearer ${mutationResponse.data.auth_leads.access_token}`,
          },
        }
      }

      changeAuth(next => {
        Object.entries(value).forEach(([k, v]) => {
          next[k] = v
        })
      })

      //calculate the timeout to warn them they will be automatically re-authenticated
      setTimeout(() => {
        notify(notifyError('Your session expired.'), 5000)
        setFlowTo('/App/Auth')
      }, value.jwt_expires_in - Math.ceil(value.access_token_data.exp - Date.now() / 1000))

      storage.setItem(`${STORAGE_KEY_AUTH}_${id}`, JSON.stringify(value))

      setFlowTo('/App/Widget')
    }

    if (id) {
      authenticate(id)
    } else {
      setFlowTo('/App/Auth/NotFound')
    }
  }, [recaptcha.isReady]) // eslint-disable-line

  return <View {...props} />
}
