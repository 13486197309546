import { useDataValue } from 'Simple/Data.js'
import { listEquals } from 'Data/aggregate.js'
import { storage } from 'Data/helpers.js'
import { STORAGE_KEY } from 'Data/constants.js'
import { notifyError, useNotifications } from 'Logic/Notifications.js'
import { useMutation } from 'urql'
import mutation from './save-progress-mutation.graphql.js'

import { getData } from './helpers.js'

function maybeComplete(flowQuestions, questions, answers) {
  let answered = flowQuestions
    .map(item => {
      let questionDetails = questions?.find(question => question.id === item)

      return answers?.[item] && !questionDetails.isInformationOnly
        ? questionDetails.id
        : false
    })
    .filter(Boolean)

  let required = questions
    .map(item => {
      return item.required && flowQuestions.includes(item.id) ? item.id : false
    })
    .filter(Boolean)

  return listEquals(required, answered)
}

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let [, executeMutation] = useMutation(mutation)
  let [, notify] = useNotifications()

  let questions = useDataValue({
    context: 'widget',
    path: 'questions',
    viewPath: props.viewPath,
  })

  let id = useDataValue({
    context: 'flow_shortcuts',
    path: 'id',
    viewPath: props.viewPath,
  })

  return async function onChange(value, change) {
    let isComplete = maybeComplete(
      value.flowQuestions,
      questions,
      value.answers
    )

    if (!isComplete && value.current_question_id) {
      // tracking progress
      await submitData({ value, questions })
    }

    // we might need to strip out the blobs here to prevent the storage from filling up,
    // we have them in the IC session anyway
    let imagecapture =
      value.answers?.imagecapture &&
      Object.entries(value.answers.imagecapture).reduce(
        (acc, [key, imageCaptureValue]) => {
          if (
            typeof imageCaptureValue === 'object' &&
            !Array.isArray(imageCaptureValue)
          ) {
            acc[key] = true
          } else {
            acc[key] = imageCaptureValue
          }

          return acc
        },
        {}
      )

    storage.setItem(
      `${STORAGE_KEY}_${id}`,
      JSON.stringify({
        ...value,
        answers: {
          ...value.answers,
          imagecapture,
        },
      })
    )

    if (isComplete === value.isComplete) return

    change(next => {
      next.isComplete = isComplete
    })
  }

  async function submitData({ value, questions }) {
    try {
      let response = await executeMutation(getData({ value, questions }))

      if (response.error) {
        return notify(
          notifyError(
            'Something went wrong. Please, try again or contact your clinic.'
          )
        )
      }
    } catch (error) {
      return notify(
        notifyError(
          'Something went wrong. Please, try again or contact your clinic.'
        )
      )
    }
  }
}
