let STORAGE_ENGINE = localStorage
let STORAGE_KEY = 'VC_CAMERA_SELECTION'
let storage = {
  setItem: (key, value) => STORAGE_ENGINE.setItem(key, value),
  getItem: key => STORAGE_ENGINE.getItem(key),
  removeItem: key => STORAGE_ENGINE.removeItem(key),
}

export function getStorageDevice() {
  return storage.getItem(STORAGE_KEY)
}

export function setStorageDevice(value) {
  storage.setItem(STORAGE_KEY, value)
}
