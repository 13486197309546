import { useDataChange, useDataValue } from 'Simple/Data'

export function useOnClick(props) {
  let question = useDataValue({
    viewPath: props.viewPath,
    context: 'imageCaptureQuestion',
  })

  let answer = useDataValue({
    viewPath: props.viewPath,
    context: 'imageCaptureQuestion',
    path: 'answer',
  })

  let questions = useDataValue({
    viewPath: props.viewPath,
    context: 'imageCapture',
    path: 'questions',
  })

  let change = useDataChange({
    viewPath: props.viewPath,
    context: 'imageCaptureSelected',
  })

  return function onClick() {
    change(next => {
      next.answers[question.id] = question?.canSkip ? 'skipped' : answer
      let next_question_index =
        questions.findIndex(item => item.id === question.id) + 1
      if (next_question_index < questions.length) {
        next.current_question_id = questions[next_question_index].id
      }
    })
  }
}

export function canSkip(question) {
  return question.type === 'picture'
    ? question.required
      ? 'Next'
      : 'Skip'
    : 'Continue'
}
