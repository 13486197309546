import { useDataSubmit } from 'Simple/Data.js'

export function useOnSubmit(props) {
  let imageCaptureSelectedSubmit = useDataSubmit({
    context: 'imageCaptureSelected',
    viewPath: props.viewPath,
  })

  return function onSubmit() {
    imageCaptureSelectedSubmit()
  }
}
