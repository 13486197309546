import 'Logic/polyfillObjectEntries.js'
import '@reach/dialog/styles.css'
import './index.css'
import './version.js'
import { Api, PublicApi } from 'Data/Api.js'
import { Auth } from 'Data/Auth.js'
import { ErrorBoundary } from 'Logic/ErrorBoundary.js'
import { FlowShortcuts } from 'Logic/FlowShortcuts.js'
import { Notifications } from 'Logic/Notifications.js'
import { DesignTokens } from 'Simple/DesignTokens.js'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './App/index.js'
import SuspenseBoundary from 'DesignSystem/SuspenseBoundary/index'

// sessionStorage.setItem(
//   'ApiMockClient',
//   process.env.REACT_APP_ENV === 'development'
// )

// not ideal but...
let error = window.console.error
window.console.error = (...args) => {
  if (/(cannot appear as a descendant of|must have either an)/.test(args[0]))
    return

  error(...args)
}

let root = createRoot(document.getElementById('root'))

root.render(
  <ErrorBoundary>
    <App>
      {({ children, viewPath }) => (
        <FlowShortcuts viewPath={viewPath}>
          <DesignTokens viewPath={viewPath}>
            <Notifications viewPath={viewPath}>
              <PublicApi viewPath={viewPath}>
                <Auth viewPath={viewPath}>
                  <Api viewPath={viewPath}>
                    <ErrorBoundary viewPath={viewPath}>
                      <SuspenseBoundary viewPath={viewPath}>
                        {children}
                      </SuspenseBoundary>
                    </ErrorBoundary>
                  </Api>
                </Auth>
              </PublicApi>
            </Notifications>
          </DesignTokens>
        </FlowShortcuts>
      )}
    </App>
  </ErrorBoundary>
)
