// this is an autogenerated file from App/Auth/SignIn/mutation.graphql
import { gql } from 'Data/Api'

export default gql`
mutation app__App_Auth_SignIn($id: String!, $captcha_token: String!) {
  auth_leads(id: $id, captcha_token: $captcha_token) {
    status
    access_token: jwt_token
    access_token_expires_in: jwt_expires_in
  }
}

`