import { EMPTY_PLACEHOLDER } from 'Data/constants.js'

export function isSkipped(value) {
  return value.answerValue === EMPTY_PLACEHOLDER && value.required === false
}

export function isAnswered(value) {
  return value && value !== EMPTY_PLACEHOLDER
}

export function isUnAnswered(value) {
  return !value || value === EMPTY_PLACEHOLDER
}
