import View from './view.js'
import React, { useMemo } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data'
import { useDesignTokenValue } from 'Simple/DesignTokens.js'

export default function CameraLogic(props) {
  let loadingBarTheme = useDesignTokenValue({
    path: 'LoadingBar',
    viewPath: props.viewPath,
  })

  let question = useDataValue({
    viewPath: props.viewPath,
    context: 'imageCaptureQuestion',
  })

  let changeQuestion = useDataChange({
    viewPath: props.viewPath,
    context: 'imageCaptureQuestion',
  })

  let changeSelected = useDataChange({
    viewPath: props.viewPath,
    context: 'imageCaptureSelected',
  })

  let appTheme = useMemo(
    () => ({
      backgroundColorBackground:
        loadingBarTheme.backgroundColor.primary.background,
      backgroundColorForeground:
        loadingBarTheme.backgroundColor.primary.foreground,
    }),
    [loadingBarTheme]
  )

  function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      let reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  async function onCapture(image) {
    let data = await blobToBase64(image)
    changeQuestion(next => {
      next.answer = {
        image: data,
        preview: data,
      }
    })
  }

  async function onClear() {
    changeQuestion(next => {
      next.answer = null
    })
    changeSelected(next => {
      next.answers[question.id] = null
    })
  }

  return (
    <View
      {...props}
      theme={appTheme}
      preview={question?.answer?.preview ?? ''}
      onCapture={onCapture}
      onClear={onClear}
      showOverlay={question.showOverlay ?? true}
    />
  )
}
