import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnClick(props) {
  let answers = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'answers',
  })

  let phoneDetails = useDataValue({
    viewPath: props.viewPath,
    context: 'phoneDetails',
  })

  let setPhoneDetails = useDataChange({
    viewPath: props.viewPath,
    context: 'phoneDetails',
  })

  return function onClick(value) {
    let patientPhoneDetails = answers['patient.phoneDetails']

    setPhoneDetails({
      ...phoneDetails,
      phoneType: value
        ? patientPhoneDetails?.phoneType
        : phoneDetails?.phoneType,
      phoneNumber: value
        ? patientPhoneDetails?.phoneNumber
        : phoneDetails?.phoneType,
      responsiblePersonPhoneDetails: {
        usePatientPhone: value,
      },
    })
  }
}
