import React from 'react'

export default React.forwardRef(function Select(props, ref) {
  let { viewPath: _, options, ...rest } = props
  return (
    <select {...rest} ref={ref}>
      {options?.map(option => (
        <option key={option.id} id={option.id} value={option.id}>
          {option.text}
        </option>
      ))}
    </select>
  )
})
