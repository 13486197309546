import { dateShortOut } from 'Simple/Data/format.js'
import { phone } from 'Data/format.js'

let KNOWN_ANSWER_KEYS = new Set([
  'patient.firstName',
  'patient.lastName',
  'patient.dateOfBirth',
  'patient.email',
  'patient.phoneDetails',
  'responsiblePerson',
  'responsiblePersonFirstName',
  'responsiblePersonFirstName',
  'responsiblePersonLastName',
  'responsiblePersonEmail',
  'responsiblePersonPhoneDetails',
  'responsiblePersonRelationship',
  'insurance',
  'location',
  'appointment',
  'imagecapture',
])

export function getData({ value, images = [], questions }) {
  return {
    person:
      (value.answers['patient.firstName'] && {
        firstName: value.answers['patient.firstName'],
        lastName: value.answers['patient.lastName'],
        birthDate: dateShortOut(value.answers['patient.dateOfBirth']),
        email: value.answers['patient.email'],
        phoneDetails: {
          type: value.answers['patient.phoneDetails']?.phoneType.toUpperCase(),
          number: phone(value.answers['patient.phoneDetails']?.phoneNumber),
          agreeOnSms: value.answers['patient.phoneDetails']?.allowNotifications,
        },
      }) ||
      null,
    responsible:
      value.answers?.responsiblePerson?.value === 'patient'
        ? null
        : (value.answers.responsiblePersonFirstName && {
            firstName: value.answers.responsiblePersonFirstName,
            lastName: value.answers.responsiblePersonLastName,
            email: value.answers.responsiblePersonEmail,
            phoneDetails: {
              type: value.answers.responsiblePersonPhoneDetails?.phoneType.toUpperCase(),
              number: phone(
                value.answers.responsiblePersonPhoneDetails?.phoneNumber
              ),
              agreeOnSms:
                value.answers.responsiblePersonPhoneDetails?.allowNotifications,
            },
            relationshipType:
              value.answers.responsiblePersonRelationship?.value.toUpperCase(),
          }) ||
          null,
    insurance: value.answers?.insurance?.value.toUpperCase(),
    location: value.answers?.location || {},
    appointment: value.answers?.appointment || {},
    images,
    answers: Object.entries(value.answers)
      .filter(([key, value]) => {
        if (KNOWN_ANSWER_KEYS.has(key) || value?.image) return false

        let question = questions.find(question => question.id === key)
        if (!question) return false

        return !question.isInformationOnly
      })
      .map(([key, value]) => {
        let question = questions.find(question => question.id === key)
        let resource = question.resource
        if (!resource) return { answer: value, id: key }

        if (resource.type === 'referral') {
          resource = {
            ...resource,
            id: question.options.find(option => option.text === value.text)
              ?.internalId,
          }
        }

        return {
          resource,
          answer: value,
          id: key,
        }
      }),
  }
}
