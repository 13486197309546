import { useDataSubmit, useDataValue } from 'Simple/Data'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'question',
  })

  let questions = useDataValue({
    viewPath: props.viewPath,
    context: 'imageCapture',
    path: 'questions',
  })

  return async function onSubmit({ value, originalValue, args, change }) {
    let result = {
      displayValues: Array('completeMsg'),
      ...value.answers,
      completeMsg: `Completed. ${
        questions.filter(item => item.type === 'picture').length
      } images added.`,
    }

    submit({ answer: result })
  }
}
