import { useDataChange, useDataValue } from 'Simple/Data'
import { useSetFlowTo, normalizePath } from 'Simple/Flow.js'
import * as validateFns from 'Data/validate.js'

/** @type {import('Simple/types.js').useDataOnSubmit} */
export default function useDataOnSubmit(props, data) {
  let setFlowTo = useSetFlowTo(props.viewPath)

  let questions = useDataValue({
    context: 'widget',
    path: 'questions',
    viewPath: props.viewPath,
  })

  let changeSelected = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
  })

  return async function onSubmit({
    value: { id, answer },
    originalValue,
    args,
  }) {
    if (args?.answer) {
      answer = args?.answer
    }

    changeSelected(next => {
      switch (args?.type) {
        case 'skip':
          next.answers[id] = { skipped: true }
          break

        case 'next':
        default:
          next.answers[id] = answer
          break
      }

      next.flowQuestions = filterFlowQuestions(questions, next.answers)

      let next_question_index =
        next.flowQuestions.findIndex(item => item === id) + 1

      if (next_question_index < next.flowQuestions.length) {
        next.current_question_id = next.flowQuestions[next_question_index]
      } else {
        setFlowTo(normalizePath(props.viewPath, '../Review'))
      }
    })
  }
}

function filterFlowQuestions(questions, answers) {
  return questions
    .filter(item => {
      if (!Array.isArray(item.dependsOn)) return true

      return item.dependsOn.every(dep => {
        let validate = validateFns?.[dep.validation]
        return validate(answers[dep.questionId]?.value, dep?.value)
      })
    })
    .map(item => item.id)
}
