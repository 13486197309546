import { captureBreadcrumb } from 'Logic/ErrorBoundary.js'
import { DataProvider } from 'Simple/Data.js'
// import { gql, useMutation } from 'Data/Api.js'
import React, { useRef } from 'react'
import makeDebug from 'Simple/debug.js'
import toSnakeCase from 'to-snake-case'

let debug = makeDebug('simple/Auth')

let value = {}
export function Auth(props) {
  let prevValue = useRef(null)

  return (
    <DataProvider
      context="auth"
      onChange={onChange}
      value={value}
      viewPath={props.viewPath}
    >
      {props.children}
    </DataProvider>
  )

  async function onChange(next) {
    let data = { api_role: next.api_role }
    if (data.api_role && data.api_role !== 'public') {
      data.id = next.access_token_data?.lead_id
    }
    captureBreadcrumb({ category: 'auth', data })

    prevValue.current = next
  }
}

export function getTimeToTokenExpirationInMs({
  access_token,
  access_token_data,
}) {
  if (!access_token) return 0

  return Math.ceil(access_token_data.exp - Date.now() / 1000) * 1000
}

export function isTokenExpired({ access_token, access_token_data }) {
  return log(access_token === null || Date.now() / 1000 > access_token_data.exp)

  function log(value) {
    if (value) {
      debug({
        type: 'Auth/isTokenExpired',
        value,
        expires_in_ms: getTimeToTokenExpirationInMs({
          access_token,
          access_token_data,
        }),
        access_token,
        access_token_data,
      })
    }
    return value
  }
}

export function getAccessTokenData(token) {
  if (token === null) return {}
  try {
    let data = JSON.parse(atob(token.split('.')[1]))
    // eslint-disable-next-line compat/compat
    let claims = Object.fromEntries(
      // eslint-disable-next-line compat/compat
      Object.entries(data['https://hasura.io/jwt/claims']).map(
        ([key, value]) => [toSnakeCase(key.replace(/^x-hasura-/, '')), value]
      )
    )
    return {
      ...data,
      ...claims,
    }
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'development') {
      debug({ type: 'auth/getAccessTokenData', error, token })
    }
    return {}
  }
}
