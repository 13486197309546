import { useEffect, useState } from 'react'
import useDebounce from 'Logic/use-debounce.js'
import qs from 'querystringify'

let DEFAULT_ZIP = qs.parse(window.location.search).zip || ''
let DIVISION_ID = qs.parse(window.location.search).division || ''

export function useSortedLocations(list, rawZip) {
  let zip = useDebounce(rawZip, 250)
  let [sorted, setSorted] = useState(list)

  useEffect(() => {
    let cancel = false
    if (!zip && !DEFAULT_ZIP) return
    ;(async () => {
      let next = await sortLocations(list, zip)

      if (cancel) return

      setSorted(next)
    })()

    return () => (cancel = true)
  }, [zip]) // eslint-disable-line
  // list doesn't matter here

  return sorted

  async function sortLocations(list, zip) {
    try {
      let {
        data: {
          appointment_widget_sort_locations: { result },
        },
      } = await executeSortLocationsQuery({
        list,
        zip: zip.toString(),
        id: DIVISION_ID,
        captcha_token: 'na',
      })

      return result
    } catch (error) {
      return null
    }
  }
}

export async function getClosestZipcodeForLocation({
  coords,
  captcha_token = 'na',
}) {
  try {
    let {
      data: {
        appointment_widget_get_closest_zipcode: { result },
      },
    } = await executeGetClosestZipCodeQuery({
      coords,
      id: DIVISION_ID,
      captcha_token,
    })

    return result && result.zip
  } catch (error) {
    return null
  }
}

async function executeGetClosestZipCodeQuery(variables) {
  let res = await fetch(process.env.REACT_APP_API, {
    method: 'POST',
    body: JSON.stringify({
      query: GET_ZIPCODE_QUERY,
      variables,
    }),
    options: {
      headers: {
        'x-hasura-role': 'public',
      },
    },
  })

  return res.json()
}

async function executeSortLocationsQuery(variables) {
  let res = await fetch(process.env.REACT_APP_API, {
    method: 'POST',
    body: JSON.stringify({
      query: SORT_LOCATIONS_QUERY,
      variables,
    }),
    options: {
      headers: {
        'x-hasura-role': 'public',
      },
    },
  })

  return res.json()
}

let SORT_LOCATIONS_QUERY = `
query appointment_widget_sort_locations(
    $captcha_token: String!
    $id: String!
    $list: [jsonb]!
    $zip: String!
  ) {
    appointment_widget_sort_locations(
      captcha_token: $captcha_token
      id: $id
      list: $list
      zip: $zip
    ) {
      result
      status
    }
  }
`

let GET_ZIPCODE_QUERY = `
query appointment_widget_get_closest_zipcode(
    $coords: jsonb!
    $captcha_token: String!
    $id: String!
  ) {
    appointment_widget_get_closest_zipcode(
      coords: $coords
      captcha_token: $captcha_token
      id: $id
    ) {
      result
      status
    }
  }
`
