// this is an autogenerated file from App/Widget/Content/Questions/Question/Content/Appointment/Content/data.graphql
import { gql } from 'Data/Api'

export default gql`
query app__App_Widget_Content_Questions_Question_Content_Appointment_Content(
  $from: String!
  $until: String!
  $location_id: numeric!
  $medical_plan: MedicalPlan!
) {
  appointment_widget_request_schedule_slots(
    from: $from
    until: $until
    location_id: $location_id
    medical_plan: $medical_plan
  ) {
    date
    slotsAvailability
  }
}

`