import { useDataValue } from 'Simple/Data.js'
import { listEquals } from 'Data/aggregate.js'
import { storage } from 'Data/helpers.js'
import { STORAGE_KEY_IMAGE_CAPTURE } from 'Data/constants.js'

function maybeComplete(flowQuestions, questions, answers) {
  let answered = flowQuestions
    .map(item => {
      let questionDetails = questions?.find(question => question.id === item)

      return answers?.[item] && !questionDetails.isInformationOnly
        ? questionDetails.id
        : false
    })
    .filter(Boolean)

  let required = questions
    .map(item => {
      return item.required && flowQuestions.includes(item.id) ? item.id : false
    })
    .filter(Boolean)

  return listEquals(required, answered)
}

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let questions = useDataValue({
    context: 'imageCapture',
    path: 'questions',
    viewPath: props.viewPath,
  })

  let id = useDataValue({
    context: 'flow_shortcuts',
    viewPath: props.viewPath,
    path: 'id',
  })

  return async function onChange(value, change) {
    let isComplete = maybeComplete(
      value.flowQuestions,
      questions,
      value.answers
    )

    storage.setItem(`${STORAGE_KEY_IMAGE_CAPTURE}_${id}`, JSON.stringify(value))

    if (isComplete === value.isComplete) return

    change(next => {
      next.isComplete = isComplete
    })
  }
}
