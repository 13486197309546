import {
  getClosestZipcodeForLocation,
  useSortedLocations,
} from 'Logic/useSortedLocations.js'

import { useUserLocation } from 'Data/userLocation.js'
import View from './view.js'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import scrollIntoView from 'scroll-into-view-if-needed'
import useDebounce from 'Logic/use-debounce.js'
import useGoogleMapsApi from 'Logic/useGoogleMapsApi.js'
import useFocusArea from 'Logic/useFocusArea.js'
import useInputKey from 'Logic/useInputKey.js'
import { useDataValue, useDataChange, useDataSubmit } from 'Simple/Data.js'

function filterLocationsByDentalPlan(locations, dentalPlan) {
  return locations.filter(({ medicalPlans }) => {
    return (
      medicalPlans &&
      medicalPlans
        .map(medicalPlan => medicalPlan.replace('_VIRTUAL', ''))
        .includes(dentalPlan.toUpperCase())
    )
  })
}

export default function Logic(props) {
  let locationsUnfiltered = useDataValue({
    context: 'widget',
    path: 'settings.locations',
    viewPath: props.viewPath,
  })

  let settingsInsurance = useDataValue({
    viewPath: props.viewPath,
    context: 'widget',
    path: 'settings.defaultInsurance',
  })

  let answers = useDataValue({
    context: 'selected',
    path: 'answers',
    viewPath: props.viewPath,
  })

  let selectedLocation = useDataValue({
    context: 'location',
    viewPath: props.viewPath,
  })

  let change = useDataChange({
    context: 'location',
    viewPath: props.viewPath,
  })

  let submitQuestion = useDataSubmit({
    viewPath: props.viewPath,
    context: 'question',
  })

  let question = useDataValue({
    viewPath: props.viewPath,
    context: 'question',
  })

  let insurance = useMemo(() => {
    return (
      answers.insurance?.value ||
      settingsInsurance?.toLowerCase()?.replace('_virtual', '')
    )
  }, [answers.insurance?.value, settingsInsurance])

  let locations = filterLocationsByDentalPlan(locationsUnfiltered, insurance)

  let [zipInput, setZipInput] = useState('')
  let [center, setCenter] = useState()
  let [highlightedLocation, setHighlightedLocation] = useState(
    selectedLocation?.locationId
  )
  let [hoveredMarker, setHoveredMarker] = useState(selectedLocation?.locationId)
  let choices = useSortedLocations(locations, zipInput)

  let userLocation = useUserLocation()

  let focusArea = useFocusArea()
  let maps = useGoogleMapsApi({
    key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })
  let listRefs = useRef({})
  let listElementRef = (id, el) => (listRefs.current[id] = el)

  let [inputKey, nextInputKey] = useInputKey()

  useEffect(() => {
    let cancel = false

    ;(async () => {
      if (!userLocation || zipInput) return
      setCenter(userLocation)

      let zipForLocation = await getClosestZipcodeForLocation({ userLocation })
      if (cancel || !zipForLocation) return

      setZipInput(zipForLocation)
      nextInputKey()
    })()

    return () => (cancel = true)
  }, [userLocation, zipInput]) // eslint-disable-line
  // nextInputKey doesn't change

  useEffect(() => {
    if (!maps.isReady || center || userLocation) return

    try {
      let bounds = new maps.api.LatLngBounds()

      locations.forEach(location => {
        bounds.extend(new maps.api.LatLng(location.lat, location.lng))
      })
      setCenter({
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      })
    } catch (e) {
      //LITTLE_ROCK_CENTER as default
      setCenter({
        lat: 34.7465,
        lng: -92.2896,
      })
    }
  }, [maps.isReady]) // eslint-disable-line

  let hoveredChoiceStable = useDebounce(hoveredMarker)
  useEffect(() => {
    if (hoveredChoiceStable && choices.length > 0) {
      let selectedMarker = choices.find(({ id }) => id === hoveredChoiceStable)
      if (selectedMarker) {
        setCenter({ lat: selectedMarker.lat, lng: selectedMarker.lng })
      }
    }
  }, [hoveredChoiceStable, choices])

  function ensureHighlightedLocationCardIsVisible() {
    let card = listRefs.current[highlightedLocation]
    if (card) {
      scrollIntoView(card, { behavior: 'smooth', scrollMode: 'if-needed' })
    }
  }
  useEffect(ensureHighlightedLocationCardIsVisible, [highlightedLocation])

  // when the user stopped hovering over cards, we want to ensure that the
  // highlighted location is visible on the list again
  let hoveredMarkerStable = useDebounce(hoveredMarker, 1000)
  useEffect(() => {
    if (!hoveredMarkerStable) {
      ensureHighlightedLocationCardIsVisible()
    }
  }, [hoveredMarkerStable]) // eslint-disable-line
  // ensureHighlightedLocationCardIsVisible doesn't count

  useEffect(() => {
    if (choices === locations) return

    let firstChoice = choices && choices[0]
    let card = listRefs.current[firstChoice && firstChoice.id]
    if (card) {
      scrollIntoView(card, { behavior: 'smooth', scrollMode: 'if-needed' })
    }
  }, [choices]) // eslint-disable-line
  // we don't care about locations here

  // the highlighted location should be unset if a marker is hovered
  let maybeHighlightedLocation = hoveredMarker ? null : highlightedLocation

  return (
    <View
      {...props}
      maps={maps}
      center={center}
      choices={choices}
      dentalPlan={insurance?.toUpperCase()}
      focusArea={focusArea}
      highlightedLocation={maybeHighlightedLocation}
      hoveredMarker={hoveredMarker}
      inputKey={inputKey}
      listElementRef={listElementRef}
      markers={locations}
      onChange={setZipInput}
      onMouseEnter={setHoveredMarker}
      onMouseLeave={() => setHoveredMarker(null)}
      onSelect={onSelect}
      selected={selectedLocation?.locationId}
      selectedLocationVisitType={selectedLocation?.locationVisitType}
      setHighlightedLocation={setHighlightedLocation}
      userLocation={userLocation}
      zipInput={zipInput}
    />
  )

  function onSelect(locationId, locationVisitType) {
    change({
      displayValues: ['text', 'subtext', 'zip'],
      locationId,
      locationVisitType,
      ...locations.find(item => item.id === locationId),
    })

    // give it some time to set the previous state
    setTimeout(
      () =>
        submitQuestion({
          type: question.required && !question?.canSkip ? 'next' : 'skip',
        }),
      100
    )
  }
}
