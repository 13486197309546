import { validate as isValidEmail } from 'email-validator'
import isPastDate from 'date-fns/isPast'
import isValidDate from 'date-fns/isValid'
import parseISODate from 'date-fns/parseISO'
import parseDate from 'date-fns/parse'
import phoneRegex from 'phone-regex'

export function equals(a, b) {
  if (!a || !b || (typeof a !== 'object' && typeof b !== 'object'))
    return a === b

  if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime()

  if (a.prototype !== b.prototype) return false

  if (
    (Array.isArray(a) && !Array.isArray(b)) ||
    (Array.isArray(b) && !Array.isArray(a))
  ) {
    return false
  }

  let keys = Object.keys(a)
  if (keys.length !== Object.keys(b).length) return false

  return keys.every(k => equals(a[k], b[k]))
}

export function email(value) {
  return isValidEmail(value)
}
// https://stackoverflow.com/a/2385967/1562732
export function name(value) {
  return (
    value &&
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð][a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u.test(
      value.trim()
    )
  )
}
export function trueFalse(value) {
  return [true, false].includes(value)
}

export function birthday(rvalue) {
  let value = !date(rvalue)
    ? parseDate(rvalue, 'MM/dd/yyyy', new Date())
    : rvalue

  return isValidDate(value) && isPastDate(value)
}

export function date(value) {
  return isValidDate(parseISODate(value))
}

export function phoneNumber(value) {
  return phoneRegex({ exact: true }).test(value)
}

export function textInput(value) {
  return text(value) && value.trim().length >= 2
}

export function text(value) {
  return typeof value === 'string'
}

export function street(value) {
  return textInput(value)
}

export function city(value) {
  return textInput(value)
}

export function state(value) {
  return value && value.length === 2
}

export function zip(value) {
  return /^\d{5}$/.test(value)
}

export function address({
  addressStreet,
  addressCity,
  addressState,
  addressZip,
}) {
  return (
    street(addressStreet) &&
    city(addressCity) &&
    state(addressState) &&
    zip(addressZip)
  )
}

export function events(value) {
  return value.length > 0
}

export function npi(value) {
  return /^\d{10}$/.test(value)
}

export function signupCode(value) {
  return /^\d{6}$/.test(value)
}

export function website(value) {
  if (!value) return true
  return value.includes('.')
}

export function isValidUrl(value) {
  try {
    new URL(value)
  } catch (_) {
    return false
  }
  return true
}

let LOWERCASE_LETTERS = /[a-z]/
let UPPERCASE_LETTERS = /[A-Z]/
let NUMBERS = /[0-9]/
let SPECIAL_CHARACTERS = /[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`]/
export function password(value) {
  return (
    value.length >= 8 &&
    LOWERCASE_LETTERS.test(value) &&
    UPPERCASE_LETTERS.test(value) &&
    NUMBERS.test(value) &&
    SPECIAL_CHARACTERS.test(value)
  )
}
// we have a password in vaxiom/core demo that is 123123123, so...
export function vaxiom_password(value) {
  return textInput(value)
}

let UUID =
  /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i
export function uuid(value) {
  return value && UUID.test(value)
}

let SOCIAL_SECURITY_NUMBER = /^\d{3}-\d{2}-\d{4}$/
export function social_security_number(value) {
  return value && SOCIAL_SECURITY_NUMBER.test(value)
}

export function numberZeroOrPositive(value) {
  return Number.isFinite(value) && value >= 0
}

export function numberPositive(value) {
  return Number.isFinite(value) && value > 0
}
export function maybe(value, fn) {
  return (typeof value !== 'number' && !value) || fn(value)
}

export function threshold(value) {
  return Number.isFinite(value) && value >= 2 && value <= 8
}

let TIME = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9]){0,1}$/
export function time(value) {
  return value && TIME.test(value)
}

export function featureFlags(value) {
  return value && value.every(e => trueFalse(e.value))
}

export function emptyList(value) {
  return Array.isArray(value) && value.length === 0
}
