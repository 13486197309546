import { useDataValue, useDataChange } from 'Simple/Data.js'
import * as validateFns from 'Data/validate.js'

/** @type {import('Simple/types.js').useDataOnChange} */
export default function useDataOnChange(props, data) {
  let questions = useDataValue({
    context: 'widget',
    path: 'questions',
    viewPath: props.viewPath,
  })

  let changeSelected = useDataChange({
    viewPath: props.viewPath,
    context: 'selected',
  })

  let selectedAnswers = useDataValue({
    viewPath: props.viewPath,
    context: 'selected',
    path: 'answers',
  })

  return async function onChange(value, change) {
    let question = questions.find(item => item.id === value.id)
    let isValid = check(question, value.answer)

    if (isValid) {
      changeSelected(next => {
        next.flowQuestions = filterFlowQuestions(questions, {
          ...selectedAnswers,
          [question.id]: value.answer,
        })
      })
    }

    change(next => {
      next.isValid = isValid
    })
  }
}

function check(question, answer) {
  // if no specific validation defined, but it's required, if the answer is set return true
  if (!question?.validation && question?.required && answer) return true
  if (question?.canSkip) return true

  // validation value must match a function in validate.js
  let validate = validateFns?.[question?.validation]

  if (answer && question.validation && typeof validate === 'function') {
    return typeof answer === 'object'
      ? validate(answer[question.validation])
      : validate(answer)
  }

  return !!(question?.required && answer)
}

function filterFlowQuestions(questions, answers) {
  let result = questions
    .filter(item => {
      if (!Array.isArray(item.dependsOn)) return true

      return item.dependsOn.every(dep => {
        let validate = validateFns?.[dep.validation]
        return validate(answers[dep.questionId]?.value, dep?.value)
      })
    })
    .map(item => item.id)

  return result
}
