import { useDataSubmit, useDataValue } from 'Simple/Data'

export function useOnKeyUp(props) {
  let submit = useDataSubmit({
    viewPath: props.viewPath,
    context: 'question',
  })

  let question = useDataValue({
    viewPath: props.viewPath,
    context: 'question',
  })

  return function onKeyUp(event) {
    if (event.key === 'Enter' && question.isValid) {
      submit({ type: 'next' })
    }
  }
}
